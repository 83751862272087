<template>
    <v-chart
    class="chartz"
    ref="chart"
    :manual-update="true"
    autoresize
    :loading="state.loading" />
</template>
  
<script>
import apiClient from '@/services/axios'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart } from 'echarts/charts'
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
} from 'echarts/components'
import { ref, defineComponent, onMounted, watch, reactive } from 'vue'
import VChart from 'vue-echarts'

use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    BarChart,
    LineChart,
    GridComponent,
])

export default defineComponent({
    name: 'EChartDataKokoh',
    components: {
        VChart,
    },
    props: {
        title: {
            default: '',
            type: String,
        },
        params: {
            default: () => ({}),
            type: [Object],
        },
    },
    setup: props => {
        const state = reactive({
            loading: true,
            endpoint: '/api/report/diagram-customer-noo-new',
            labels: [],
            data: [],
        })
        const chart = ref()

        const option = ref({
            title: {
                text: props.title,
                left: 'center',
                textStyle: {
                    overflow: 'break',
                    fontSize: '1rem',
                },
            },
            textStyle: {
                overflow: 'break',
                fontSize: '0.8rem',
                width: 120,
            },
            grid: {
                containLabel: true,
                left: '3%',
                right: '4%',
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: {
                type: 'scroll',
                bottom: 0,
                padding: [0, 0, 0, 0],
            },
            xAxis: [
                {
                    type: 'category',
                    axisLabel: {
                        interval: 0,
                    },
                    data: state.labels,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: function (a) {
                            const formatter = Intl.NumberFormat('id-ID', { notation: 'compact' })
                            return formatter.format(a)
                        },
                    },
                },
            ],
            series: [
                {
                    name: 'Regional Toko Noo',
                    type: 'bar',
                    emphasis: {
                        focus: 'series',
                    },
                    color: '#66BFBF',
                    data: state.data,
                },
            ],
            media: [
                {
                    query: {
                        maxWidth: 300, // when container width is smaller than 300
                    },
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'break',
                                width: 230,
                                fontSize: '.9rem',
                            },
                        },
                    },
                },
                {
                    query: {
                        minWidth: 300, // when container width is smaller than 300
                        maxWidth: 500, // when container width is smaller than 500
                    },
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'break',
                                width: 300,
                            },
                        },
                    },
                },
                {
                    option: {
                        title: {
                            text: props.title,
                            left: 'center',
                            textStyle: {
                                overflow: 'none',
                            },
                        },
                    },
                },
            ],

        })


        const fetchData = () => {
            state.labels.splice(0, state.labels.length)
            state.data.splice(0, state.data.length)

            apiClient
                .get(state.endpoint, {
                params: props.params,
                })
                .then(({ data }) => {
                    if (data.items) {
                        data.items.forEach(item => {
                            state.labels.push(item.regional)
                            state.data.push(item.id_toko_noo || 0)
                        })
                    }
                })
                .catch(error => console.error(error))
                .finally(() => {
                    state.loading = false
                })
        }

        onMounted(() => {
            chart.value.setOption(option.value)
            fetchData()
        })

        watch(state.data, () => {
            chart.value.setOption(option.value)
        })

        return {
            state,
            chart,
        }
    },
})
</script>
  
<style>
.chartz {
    height: 300px;
}
</style>
  