<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AMonthPicker
                    v-model:value="state.params.month"
                    placeholder="Pilih Bulan"
                    :disabled-date="disabledDate"
                    style="width:100%;"
                    format="MMMM YYYY"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    v-model:value="state.params.toko"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterBrand
                    style="width:100%;"
                    v-model:value="state.params.brand"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-5">
            <div class="col-lg-12 col-md-12 mt-auto">
                <div class="card">
                    <div class="card-body">
                        <GraphBarChart
                            v-if="!state.isFetching"
                            :params="queryParams()"
                            title="Diagram Toko Noo"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
                <strong>{{ state.openClosePriodeLastTime }}</strong>
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <ASpace>
                    <ATooltip title="Reset Redis">
                        <AButton
                            :loading="state.isReset"
                            type="primary"
                            :disabled="!state.openClosePriode"
                            @click="btnSynchronReset()">
                            <i class="fa fa-repeat"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Syncron">
                        <AButton
                            :loading="state.isSync"
                            type="primary"
                            :disabled="!state.openClosePriode"
                            @click="btnSynchron()">
                            <i class="fa fa-refresh"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Download Excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            namefile="Data-toko-NOO"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint.current"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue'
import apiClient from '@/services/axios'
import {
    FilterRegional,
    FilterArea,
    FilterDistributor,
    FilterBrand,
} from '@/components/filter'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import GraphBarChart from './GraphBarChart'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterRegional,
        FilterArea,
        FilterDistributor,
        FilterToko,
        FilterBrand,
        DownloadExcel,
        TableStandard,
        GraphBarChart,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'tanggal',
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'kode_toko',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'wilayah',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Regional',
                    dataIndex: 'regional',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'kode_distributor',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'nama_distributor',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'kode_produk',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'nama_produk',
                },
                {
                    title: 'Brand',
                    dataIndex: 'brand',
                },
                {
                    title: 'Zak Quantity',
                    dataIndex: 'qty',
                },
                {
                    title: 'Ton Quantity',
                    dataIndex: 'qty_ton',
                },
                {
                    title: 'Harga',
                    dataIndex: 'price',
                },
                {
                    title: 'Cluster',
                    dataIndex: 'cluster_name',
                },
                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
            ],
            endpoint: {
                current: '/api/report/customer-noo-v2-new',
                sync: '/api/synchrons/transaction-noo',
                reset: '/api/report/refresh-detail-trx-toko-noo-v2',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isSync: false,
            isReset: false,
            params: {
                q: '',
                region: [],
                area: [],
                brand: [],
                distributor: [],
                toko: [],
                month: moment(new Date()),
                page: 1,
                "per-page": 10,
            },
            openClosePriode: true,
            openClosePriodeLastTime: '-',
        })

        const disabledDate = (current) => {
            return current.isAfter(new Date(), "month")
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.month) {
                params.month = moment(state.params.month).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            fetchSynchOpenPeriode()
            fetchSynchOpenPeriodeLastTime()
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        const fetchSynchOpenPeriode = () => {
            apiClient
                .get(`/api/synchron-open/cek`, {
                    params: {
                        periode: queryParams().month,
                    },
                })
                .then(({ data }) => {
                    state.openClosePriode = data.openClosePriode
                })
        }

        const fetchSynchOpenPeriodeLastTime = () => {
            apiClient
                .get(state.endpoint.current, {
                    params: {
                        month: queryParams().month,
                        _data: 'synchron',
                    },
                })
                .then(({ data }) => {
                    state.openClosePriodeLastTime = data.last_synchron
                })
        }

        const btnSynchron = () => {
            state.isSync = true
            apiClient
                .post(state.endpoint.sync, {
                    month: queryParams().month,
                })
                .then(({ data }) => {
                    message.success(data.message)
                })
                .finally(() => {
                    fetchSynchOpenPeriodeLastTime()
                    state.isSync = false
                })
        }

        const btnSynchronReset = () => {
            state.isReset = true
            apiClient
                .get(state.endpoint.reset, {
                    params: {
                        month: queryParams().month,
                    },
                })
                .then(({ data }) => {
                    message.success(data.message)
                })
                .finally(() => {
                    fetchSynchOpenPeriodeLastTime()
                    state.isReset = false
                })
        }

        onMounted(() => {
            fetchSynchOpenPeriode()
            fetchSynchOpenPeriodeLastTime()
        })

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            disabledDate,
            btnSynchron,
            btnSynchronReset,
        }
    },
})
</script>
